import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiIcon } from '../src';
import { IconsList } from './utils';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uiicon"
    }}>{`UiIcon`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/icons/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render icons in a web application`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/icons`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiicon-1"
    }}>{`UiIcon`}</h2>
    <Playground __position={1} __code={'<UiIcon icon=\"Search\" size=\"xlarge\" category=\"positive\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiIcon,
      IconsList,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiIcon icon="Search" size="xlarge" category="positive" mdxType="UiIcon" />
    </Playground>
    <h2 {...{
      "id": "uiicon-with-inverse-coloration"
    }}>{`UiIcon with inverse coloration`}</h2>
    <Playground __position={2} __code={'<UiIcon icon=\"Search\" size=\"xlarge\" category=\"tertiary\" inverseColoration />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiIcon,
      IconsList,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiIcon icon="Search" size="xlarge" category="tertiary" inverseColoration mdxType="UiIcon" />
    </Playground>
    <h2 {...{
      "id": "uiicon-with-default-coloration"
    }}>{`UiIcon with default coloration`}</h2>
    <Playground __position={3} __code={'<UiIcon icon=\"Search\" size=\"xlarge\" category=\"tertiary\" coloration=\"dark\" />{\' \'}\nAlways render DARK coloration <br />\n<UiIcon\n  icon=\"Search\"\n  size=\"xlarge\"\n  category=\"tertiary\"\n  coloration=\"light\"\n/>{\' \'}\nAlways render LIGHT coloration'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiIcon,
      IconsList,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiIcon icon="Search" size="xlarge" category="tertiary" coloration="dark" mdxType="UiIcon" /> Always render DARK coloration <br />
  <UiIcon icon="Search" size="xlarge" category="tertiary" coloration="light" mdxType="UiIcon" /> Always render LIGHT coloration
    </Playground>
    <h2 {...{
      "id": "icons-list"
    }}>{`Icons list`}</h2>
    <Playground __position={4} __code={'<IconsList />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiIcon,
      IconsList,
      DefaultLayout,
      _frontmatter
    }} hideThemeSelector mdxType="Playground">
  <IconsList mdxType="IconsList" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiIcon} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      